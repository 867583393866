'use strict'

###*
 # @ngdoc service
 # @name ReportSourceManager.factory:ReportSourceManager

 # @description

###
angular
  .module 'mundoReporting'
  .factory 'ReportSourceManager', [
    'MundoDefaultManager'
    '$q'
    'Restangular'
    (
      MundoDefaultManager
      $q
      Restangular
    ) ->
      ReportSourceManagerBase = new MundoDefaultManager()
      ReportSourceManagerBase.setUrl('reports/sources')

      ReportSourceManagerBase.one = (source) ->
        @transport.one('reports/sources', source).get()

      ReportSourceManagerBase.getListCustom = ->
        $q (resolve, reject) =>
          sources = []

          Restangular.all(@conf.url).customGET("").then (data) ->
            for k, v of data.results
              sources.push v

            resolve sources
          , () ->
            reject()

      ReportSourceManagerBase.getList = ->
        @getListCustom()

      ReportSourceManagerBase
  ]
